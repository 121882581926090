import { defineComponent } from '@vue/composition-api';
import { ref } from 'vue';
import { areaList } from '@vant/area-data';
import { HPV_SUBSCRIBE, HPV_SUBSCRIBE_SETTING } from '../../http/api';
import { Toast } from 'vant';
export default defineComponent({
  setup() {
    const checked = ref(true);
    const select2Show = ref(false);
    const select3Show = ref(false);
    const city1Show = ref(false);
    const city2Show = ref(false);
    const city3Show = ref(false);
    const city1Name = ref('');
    const city2Name = ref('');
    const city3Name = ref('');
    const city1Value = ref('');
    const city2Value = ref('');
    const city3Value = ref('');
    const vtype = ref([9]);
    const ntype = ref([1, 2, 3]);
    const confirmShow = ref(false);
    const isConfirm = ref(false);
    const cascaderValue = ref('');
    const form = ref(null);
    HPV_SUBSCRIBE_SETTING().then(res => {
      Toast.clear();
      const data = res.data.data;

      if (data.ntype.length > 0) {
        ntype.value = data.ntype;
      }

      if (data.vtype.length > 0) {
        vtype.value = data.vtype;
      }

      if (data.citys && data.citys.length > 0) {
        city1Name.value = data.citys[0].name;
        city1Value.value = data.citys[0].code;
      }

      if (data.citys && data.citys.length > 1) {
        city2Name.value = data.citys[1].name;
        city2Value.value = data.citys[1].code;
        select2Show.value = true;
      }

      if (data.citys && data.citys.length > 2) {
        city3Name.value = data.citys[2].name;
        city3Value.value = data.citys[2].code;
        select3Show.value = true;
      }
    }); // 全部选项选择完毕后，会触发 finish 事件

    const city1OnFinish = selectedOptions => {
      city1Show.value = false;
      city1Name.value = selectedOptions[1].name;
      city1Value.value = selectedOptions[1].code;
    }; // 全部选项选择完毕后，会触发 finish 事件


    const city2OnFinish = selectedOptions => {
      city2Show.value = false;
      city2Name.value = selectedOptions[1].name;
      city2Value.value = selectedOptions[1].code;
    }; // 全部选项选择完毕后，会触发 finish 事件


    const city3OnFinish = selectedOptions => {
      city3Show.value = false;
      city3Name.value = selectedOptions[1].name;
      city3Value.value = selectedOptions[1].code;
    };

    const city1OnCancel = () => {
      city1Show.value = false;
    };

    const city2OnCancel = () => {
      city2Show.value = false;
    };

    const city3OnCancel = () => {
      city3Show.value = false;
    };

    const cityClose2 = e => {
      e.stopPropagation();
      select2Show.value = false;
      city2Name.value = "";
      city2Value.value = "";
    };

    const cityClose3 = e => {
      e.stopPropagation();
      select3Show.value = false;
      city3Name.value = "";
      city3Value.value = "";
    };

    const addCity = () => {
      if (select2Show.value == false) {
        select2Show.value = true;
      } else if (select3Show.value == false) {
        select3Show.value = true;
      }
    };

    const onSubmit = values => {
      let vtypes = JSON.parse(JSON.stringify(values.vtype)); //let ntypes = JSON.parse(JSON.stringify(values.ntype))

      var formData = {
        city1: city1Value.value,
        city2: city2Value.value,
        city3: city3Value.value,
        vtype: vtypes,
        ntype: [1, 2, 3]
      }; // for (let v in ntypes) {
      //     if (ntypes[v] === 3 && !isConfirm.value) {
      //         //confirmShow.value = true
      //         //return
      //     }
      // }

      HPV_SUBSCRIBE(formData).then(res => {
        Toast.clear();

        if (res.data.code !== 0) {
          if (res.data.code === -106) {
            Toast.fail({
              duration: 1000,
              message: "未开通会员或已过期",

              onClose() {
                window.location.href = "/#vip";
              }

            });
          } else {
            Toast.fail(res.data.msg);
          }
        } else {
          Toast.success({
            duration: 3000,
            message: "订阅成功。\n请置顶九四订阅公众号",

            onClose() {
              window.location.href = "/#close_dr";
            }

          });
        }
      });
    }; // 确认并继续


    function confirmed() {
      var _form$value;

      isConfirm.value = true;
      confirmShow.value = false;
      (_form$value = form.value) === null || _form$value === void 0 ? void 0 : _form$value.submit();
    }

    return {
      checked,
      vtype,
      ntype,
      city2Show,
      city1Show,
      city3Show,
      city1OnFinish,
      city2OnFinish,
      city3OnFinish,
      city1Value,
      city2Value,
      city3Value,
      city1Name,
      city2Name,
      city3Name,
      cascaderValue,
      areaList,
      onSubmit,
      confirmShow,
      confirmed,
      form,
      city1OnCancel,
      city2OnCancel,
      city3OnCancel,
      cityClose2,
      cityClose3,
      select2Show,
      select3Show,
      addCity
    };
  }

});